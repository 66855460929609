import { Component, OnInit, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
    selector: "app-snackbar",
    templateUrl: "./snackbar.component.html",
    styleUrls: ["./snackbar.component.scss"]
})
export class AppSnackbarComponent implements OnInit {
    constructor(
        public sbRef: MatSnackBarRef<AppSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }
    ngOnInit() {
    }
}
