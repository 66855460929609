import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@service/auth.service';
import { RMService } from '@service/rm.service';
import { map } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mars-rm';
  isLoggedIn: boolean = false
  currentQuery: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private RMservice: RMService
  ){
  }


  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res) => this.isLoggedIn = res)
    this.router.events.pipe(
      map((x)=> x instanceof NavigationEnd)
    ).subscribe((res) => {
      if(res) {
        window.scroll({top: 0})
        document.getElementById('container')?.scrollTo({top: 0})
      }
    })
    this.authService.getToken()
    setTimeout(() => {
      if(this.isLoggedIn){
        this.RMservice.checkWorkfFlowData()
      }
    }, 100);
   
   
    // const state = window.localStorage.getItem(environment.stateId)
    // if(state && JSON.parse(state).currentPortfolioData && JSON.parse(state).reviewPortfolioData){
    //   this.RMservice.saveStateData(JSON.parse(state))
    // }
  }
}
