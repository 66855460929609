import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_END_POINTS } from '@app/config/api.constants';
import { WorkflowData } from '@app/config/worflow.interface';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RMService {

  private $workflowData: BehaviorSubject<WorkflowData> = new BehaviorSubject({ panNo: '', algoWorkflowId: '', profileId: '' })
  workFlowData = this.$workflowData.asObservable()

  private $userProfile: BehaviorSubject<any> = new BehaviorSubject(null)
  userProfile = this.$userProfile.asObservable()

  private $selectedConfig: BehaviorSubject<string> = new BehaviorSubject<string>('')

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  saveWorkflowData(data: WorkflowData) {
    window.localStorage.setItem('workflow', JSON.stringify(data))
    this.$workflowData.next(data)
  }

  checkWorkfFlowData() {
    const workflow = JSON.parse(window.localStorage.getItem('workflow') || '{}')
    if (workflow && (workflow.panNo || workflow.profileId || workflow.algoWorkflowId)) {
      this.$workflowData.next(workflow)
      if (workflow.algoWorkflowId) {
        this.getUserDataBYworkflow(workflow.algoWorkflowId)
      }
    }
  }

  onboardUser(data: any): Observable<any> {
    return this.http.post(API_END_POINTS.RM.onboard, data)
  }

  startUserWorkflow(configId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.initiate_session}?configId=${configId}`, data)
  }

  getUserProfile(panNo: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.user_byPan}?panNo=${panNo}`)
  }

  setUserProfile(data: any) {
    this.$userProfile.next(data)
  }

  getRMDistribution(workflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.rm_distribution}?algoWorkflowId=${workflowId}`)
  }

  getUserCassStatement(workflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.user_cass}?algoWorkflowId=${workflowId}`)
  }

  getUserPortfolioInstruments(workflowId: string, filterType: 'ALL' | 'MANUAL' | 'CASLOAD' | 'NOTINCONSIDERATION' | 'IGNORED', query?: any): Observable<any> {
    if (query) {
      const routeQuery = this.getObjectToQueryString(query)
      return this.http.get(`${API_END_POINTS.RM.current_portfolio_instruments}?algoWorkflowId=${workflowId}&filterType=${filterType}&${routeQuery}`)
    } else {
      return this.http.get(`${API_END_POINTS.RM.current_portfolio_instruments}?algoWorkflowId=${workflowId}&filterType=${filterType}`)
    }
  }

  getCurrentUserAllocation(workflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.current_portfolio_allocation}?algoWorkflowId=${workflowId}`)
  }

  getCurrentPortfolio(workflowId: string, filterType: 'ALL' | 'MANUAL' | 'CASLOAD' | 'NOTINCONSIDERATION' = 'ALL'): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.current_portfolio_distribution}?algoWorkflowId=${workflowId}&filterType=${filterType}`)
  }

  getCategoryColors(categoryName: string): string {
    switch (categoryName) {
      case 'Debt':
        return 'blue'
      case 'Cash':
        return 'green'
      case 'AIF':
        return 'red'
      case 'Equity':
        return 'purple'
      case 'Gold':
        return 'yellow'
      case 'Real_Estate':
        return 'orange'
      case 'Others':
        return 'black'
      default: return 'black'
    }
  }


  redemptionControl(workflowId: string, pickrightAssetId: string, value: boolean): Observable<any> {
    return this.http.put(`${API_END_POINTS.RM.scrip_redemption}?algoWorkflowId=${workflowId}&pickrightAssetId=${pickrightAssetId}&value=${value}`, {})
  }

  markInConsider(workflowId: string, pickrightAssetId: string, value: 'YES' | 'NO'): Observable<any> {
    return this.http.put(`${API_END_POINTS.RM.scrip_consideration}?algoWorkflowId=${workflowId}&pickrightAssetId=${pickrightAssetId}&value=${value}`, {})
  }

  startProcess(workflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.start_process}?algoWorkflowId=${workflowId}`)
  }

  addInvestmentUniverse(algoWorkflowId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.green_universe}?algoWorkflowId=${algoWorkflowId}`, data)
  }

  getInvestmentUniverse(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.green_universe}?algoWorkflowId=${algoWorkflowId}`)
  }

  updateInvestmentUniverse(algoWorkflowId: string, pickrightAssetId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.green_universe}?algoWorkflowId=${algoWorkflowId}&pickrightAssetId=${pickrightAssetId}`, data)
  }

  removeInvestmentUniverse(algoWorkflowId: string, pickrightAssetId: string): Observable<any> {
    return this.http.delete(`${API_END_POINTS.RM.green_universe}?algoWorkflowId=${algoWorkflowId}&pickrightAssetId=${pickrightAssetId}`)
  }

  addRedemptionUniverse(algoWorkflowId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.red_universe}?algoWorkflowId=${algoWorkflowId}`, data)
  }

  getRedemptionUniverse(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.red_universe}?algoWorkflowId=${algoWorkflowId}`)
  }

  removeRedemptionUniverse(algoWorkflowId: string, pickrightAssetId: string): Observable<any> {
    return this.http.delete(`${API_END_POINTS.RM.red_universe}?algoWorkflowId=${algoWorkflowId}&pickrightAssetId=${pickrightAssetId}`)
  }

  addUserInvestment(algoWorkflowId: string, profileId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.investments}?algoWorkflowId=${algoWorkflowId}&profileId=${profileId}`, data)
  }

  removeUserInvestment(algoWorkflowId: string, pickrightAssetId: string): Observable<any> {
    return this.http.delete(`${API_END_POINTS.RM.delete_investment}?algoWorkflowId=${algoWorkflowId}&pickrightAssetId=${pickrightAssetId}`)
  }

  getAllSessions(query?: string): Observable<any> {
    if (query) {
      return this.http.get(`${API_END_POINTS.RM.session_history}?${query}`)
    } else {
      return this.http.get(API_END_POINTS.RM.session_history)
    }
  }

  endSession() {
    window.localStorage.removeItem('workflow')
    this.$workflowData.next({ panNo: '', algoWorkflowId: '', profileId: '' })
    this.router.navigate(['/'])
  }

  getSmartRebalValue(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.smart_reblance}?algoWorkflowId=${algoWorkflowId}`)
  }
  getRebalValue(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.reblance_type}?algoWorkflowId=${algoWorkflowId}`)
  }

  smartRebalance(workflowId: string, rebalanceType: string, bossValue?: number, presetConfigId?: string): Observable<any> {
    if (presetConfigId) {
      return this.http.get(`${API_END_POINTS.RM.smart_comparator}?workflowId=${workflowId}&rebalanceType=${rebalanceType}&bossValue=${bossValue}&presetConfigId=${presetConfigId}`)
    } else {
      return this.http.get(`${API_END_POINTS.RM.smart_comparator}?workflowId=${workflowId}&rebalanceType=${rebalanceType}&bossValue=${bossValue}`)
    }
  }

  calculatePortfolioReport(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.portfolio_report}?algoWorkflowId=${algoWorkflowId}`)
  }

  getObjectToQueryString(object: any) {
    return Object.keys(object)
      .map((key) => key + '=' + object[key])
      .join('&');
  }

  searchForWorkflow(searchText: string, sortBy: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.worflow_search}?searchText=${searchText}&sortBy=${sortBy}`)
  }

  getFinalStat(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.final_stat}?algoWorkflowId=${algoWorkflowId}`)
  }

  getFinalInvestment(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.final_investment}?algoWorkflowId=${algoWorkflowId}`)
  }

  getCategoryShads(category: string, index: number): { text: string, fill: string } {
    const categoryColor = this.getCategoryColors(category)
    const value = (index + 1) % 12
    return { fill: `${categoryColor}-${value}`, text: value > 6 ? '#000' : '#fff' }
  }

  addNewInvestment(algoWorkflowId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.add_investment}?algoWorkflowId=${algoWorkflowId}`, data)
  }

  getPortfolioVsRecommend(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.portfolio_vs_recommened}?algoWorkflowId=${algoWorkflowId}`)
  }

  private getUserDataBYworkflow(algoWorkflowId: string) {
    this.http.get(`${API_END_POINTS.RM.user_byWorkflow}?algoWorkflowId=${algoWorkflowId}`).subscribe((res: any) => {
      this.setUserProfile(res)
      if (res.currentStep) {
        this.$workflowData.value.currentStep = res.currentStep
        this.$workflowData.value.greenUniverseConfig = res.greenUniverseConfig
        this.$workflowData.value.redUniverseConfig = res.redUniverseConfig
        this.$workflowData.value.configCriteria = res.configCriteria
        this.$workflowData.value.isPublishedListEnable = res.isPublishedListEnable
        this.$workflowData.value.parameters = res.parameters
        this.saveWorkflowData({ ...this.$workflowData.value })
      }
    })
  }

  updateRecommendedData(algoWorkflowId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.update_recommended}?algoWorkflowId=${algoWorkflowId}`, data)
  }

  resetToDefault(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.reset_default}?algoWorkflowId=${algoWorkflowId}`)
  }

  getAssetAllocation(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.asset_allocation}?algoWorkflowId=${algoWorkflowId}`)
  }

  deleteRecommenedAsset(algoWorkflowId: string, pickrightAssetId: string, isDeleteForSellDisable: boolean): Observable<any> {
    return this.http.delete(`${API_END_POINTS.RM.delete_recommended}?algoWorkflowId=${algoWorkflowId}&pickrightAssetId=${pickrightAssetId}&isDeleteForSellDisable=${isDeleteForSellDisable}`)
  }

  adminPresets(): Observable<any> {
    return this.http.get(API_END_POINTS.ADMIN.presets)
  }

  getCustomMinValue(algoWorkflowId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.custom_minValue}?algoWorkflowId=${algoWorkflowId}`, data)
  }

  customRebalance(workflowId: string, rebalanceType: "CUSTOM" | "CUSTOM_BOSS", bossValue: number, data: any) {
    return this.http.post(`${API_END_POINTS.RM.custom_comparator}?algoWorkflowId=${workflowId}&rebalanceType=${rebalanceType}&bossValue=${bossValue}`, data)
  }

  presetRebalance(workflowId: string, rebalanceType: string, bossValue?: number, presetConfigId?: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.smart_comparator}?workflowId=${workflowId}&rebalanceType=${rebalanceType}&bossValue=${bossValue}&presetConfigId=${presetConfigId}`)
  }

  idealAllocation(algoWorkflowId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.ideal_allocation}?algoWorkflowId=${algoWorkflowId}`)
  }

  presetMinValue(algoWorkflowId: string, presetConfigId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.preset_minValue}?algoWorkflowId=${algoWorkflowId}&presetConfigId=${presetConfigId}`)
  }

  getReviewPortfolioGroupData(algoWorkflowId: string, action: 'ALL' | 'NOCHANGE' | 'SELL' | "BUY" = 'ALL'): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.review_portfolio_group}?algoWorkflowId=${algoWorkflowId}&action=${action}`)
  }
  
  getReviewPortfolioInstruments(workflowId: string, filterType: 'ALL' | 'MANUAL' | 'CASLOAD' | 'NOTINCONSIDERATION', query?: any): Observable<any> {
    if(query){
      const routeQuery = this.getObjectToQueryString(query)
      return this.http.get(`${API_END_POINTS.RM.review_portfolio_instruments}?algoWorkflowId=${workflowId}&filterType=${filterType}&${routeQuery}`)
    } else {
      return this.http.get(`${API_END_POINTS.RM.review_portfolio_instruments}?algoWorkflowId=${workflowId}&filterType=${filterType}`)
    }
  }

  updateReviewPortfData(algoWorkflowId: string, data: any): Observable<any>{
    return this.http.post(`${API_END_POINTS.RM.review_instrument_update}?algoWorkflowId=${algoWorkflowId}`, data)
  }

  ignoreReviewPortfData(algoWorkflowId: string, data: {action?: 'BUY' | 'SELL', pickrightAssetId?: string, assetParent?:string, assetChildren?: string}) : Observable<any> {
    const query  = this.getObjectToQueryString(data)
    return this.http.delete(`${API_END_POINTS.RM.review_instrument_ignore}?algoWorkflowId=${algoWorkflowId}&${query}`)
  }

  rollback(algoWorkflowId: string, dataId: string, type: 'instrument'| 'childAsset' | 'parentAsset' | 'all') : Observable<any> {
    switch(type){
      case 'instrument':
        return this.rollbackInstrument(algoWorkflowId, dataId)
      case 'childAsset':
        return this.rollbackChildAsset(algoWorkflowId, dataId)
      case 'parentAsset':
        return this.rollbackParentAsset(algoWorkflowId, dataId)
      default:
        return this.completeRollback(algoWorkflowId)
    }
  }

  rollbackInstrument(algoWorkflowId: string, pickrightAssetId: string) : Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.review_rollback}?algoWorkflowId=${algoWorkflowId}&pickrightAssetId=${pickrightAssetId}`)
  }

  rollbackChildAsset(algoWorkflowId: string, assetChildren: string) : Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.review_rollback}?algoWorkflowId=${algoWorkflowId}&assetChildren=${assetChildren}`)
  }

  rollbackParentAsset(algoWorkflowId: string, assetParent: string) : Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.review_rollback}?algoWorkflowId=${algoWorkflowId}&assetParent=${assetParent}`)
  }
  
  completeRollback(algoWorkflowId: string) : Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.review_rollback}?algoWorkflowId=${algoWorkflowId}`)
  }

  ignoreBuySell(algoWorkflowId: string, action: 'BUY'|'SELL', type: 'parent'| 'children', dataId: string): Observable<any> {
    if(type === 'parent'){
      return this.http.delete(`${API_END_POINTS.RM.review_instrument_ignore}?algoWorkflowId=${algoWorkflowId}&action=${action}&assetParent=${dataId}`)
    } else {
      return this.http.delete(`${API_END_POINTS.RM.review_instrument_ignore}?algoWorkflowId=${algoWorkflowId}&action=${action}&assetChildren=${dataId}`)
    }
  }

  rollbackBuySell(algoWorkflowId: string, action: 'BUY'|'SELL', type: 'parent'| 'children', dataId: string): Observable<any> {
    if(type === 'parent'){
      return this.http.get(`${API_END_POINTS.RM.review_rollback}?algoWorkflowId=${algoWorkflowId}&action=${action}&assetParent=${dataId}`)
    } else {
      return this.http.get(`${API_END_POINTS.RM.review_rollback}?algoWorkflowId=${algoWorkflowId}&action=${action}&assetChildren=${dataId}`)
    }
  }

  getPortfolioNotes(query): Observable<any>{
      return this.http.get(`${API_END_POINTS.RM.portfolio_notes}?${query}`)
  }

  createPortfolioNotes(noteType: string, data:any): Observable<any>{
    return this.http.post(`${API_END_POINTS.RM.create_portfolio_notes}?NotesType=${noteType}`,data)
  }

  updatePortfolioNotes(notesId: string, data:any): Observable<any>{
    return this.http.post(`${API_END_POINTS.RM.update_portfolio_notes}?portfolioNotesId=${notesId}`,data)
  }

  getProjectionInstrument(algoWorkflowId: string,assetChildren:string,action: 'ALL' | 'NOCHANGE' | 'SELL' | "BUY" = 'ALL'): Observable<any>{
    return this.http.get(`${API_END_POINTS.RM.review_portfolio_instruments}?algoWorkflowId=${algoWorkflowId}&action=${action}&assetChildren=${assetChildren}`)
  }

  getProjectionBuyInstrument(algoWorkflowId: string,assetParent:string): Observable<any>{
    return this.http.get(`${API_END_POINTS.RM.review_portfolio_instruments}?algoWorkflowId=${algoWorkflowId}&action=BUY&assetParent=${assetParent}`)
  }

  getSellGroupFilter(algoWorkflowId: string): Observable<any>{
    return this.http.get(`${API_END_POINTS.RM.sell_instruments_group}?algoWorkflowId=${algoWorkflowId}`)
  }

  getSellAssetGroupData(algoWorkflowId: string, sellType: "TAIL"| "RED"| "PROPORTIONATE"| "ALL"): Observable<any>{
    return this.http.get(`${API_END_POINTS.RM.review_portfolio_group}?algoWorkflowId=${algoWorkflowId}&action=SELL&sellType=${sellType}`)
  }

  getSellAssetInstrument(algoWorkflowId: string,assetParent:string,sellType: "TAIL"| "RED"| "PROPORTIONATE"| "ALL"): Observable<any>{
    return this.http.get(`${API_END_POINTS.RM.review_portfolio_instruments}?algoWorkflowId=${algoWorkflowId}&action=SELL&assetParent=${assetParent}&sellType=${sellType}`)
  }

  reportDownload(query: string): Observable<any>{
    return this.http.get(`${API_END_POINTS.RM.report_download}?${query}`, {responseType: 'arraybuffer'})
  }

  addInstrumentToAlgo(algoWorkflowId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.add_instruments}?algoWorkflowId=${algoWorkflowId}`, data)
  }

  getReviewPortfolioIndividualData(algoWorkflowId: string, action: 'ALL' | 'NOCHANGE' | 'SELL' | "BUY" = 'ALL', parentId: string, childId?: string): Observable<any> {
    if(childId){
      return this.http.get(`${API_END_POINTS.RM.review_portfolio_group}?algoWorkflowId=${algoWorkflowId}&action=${action}&assetParent=${parentId}&assetChildren=${childId}`)
    } else {
      return this.http.get(`${API_END_POINTS.RM.review_portfolio_group}?algoWorkflowId=${algoWorkflowId}&action=${action}&assetParent=${parentId}`)
    }
  }

  getTailValues(algoWorkflowId: string): Observable<any>{
    return this.http.get(`${API_END_POINTS.RM.tail_config_values}?algoWorkflowId=${algoWorkflowId}`)
  }

  resumeWorkflow(workflow: any){
    this.checkWorkfFlowData()
    switch(workflow.currentStep){
      case 'INITIATED':
        this.router.navigate(['/portfolio'])
        break;
      case 'CURRENT_PORTFOLIO':
        this.router.navigate(['/portfolio/current'])
        break;
      case 'TAGGING_DONE':
        this.router.navigate(['/portfolio/analysis-method'])
        break;
      case 'SMART_REBALANCING_CALCULATED':
        this.router.navigate(['/portfolio/analysis-method'])
        break;
      case 'REVIEW_PORTFOLIO':
        this.router.navigate(['/portfolio/review'])
        break;
      case 'PORTFOLIO_REPORT':
        this.router.navigate(['/portfolio/portfolio-report'])
        break;
      default: 
        this.router.navigate(['/portfolio'])
        break;
    }
   
  }

  getAllConfig(): Observable<any> {
    return this.http.get(API_END_POINTS.RM.config_prest)
  }

  public set setConfig(configId: string){
    this.$selectedConfig.next(configId)
  }

  public get currentConfig(): Observable<string>{
    return this.$selectedConfig.asObservable()
  }

  getAllGreenConfig(): Observable<any> {
    return this.http.get(API_END_POINTS.RM.green_config)
  }

  getAllRedConfig(): Observable<any> {
    return this.http.get(API_END_POINTS.RM.red_config)
  }
  updateCurrentInstrument(algoWorkflowId: string, data: any): Observable<any>{
    return this.http.post(`${API_END_POINTS.RM.update_current_portfolio_instrument}?algoWorkflowId=${algoWorkflowId}`, data)
  }

  getCurrentConfig(configId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.configCriteria}?configId=${configId}`)
  }

  getConfigParamaters(configId: string): Observable<any> {
    return this.http.get(`${API_END_POINTS.RM.config_params}?configId=${configId}`)
  }

  updateUniverse(algoWorkflowId: string, data: any): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.universe_update}?algoWorkflowId=${algoWorkflowId}`, data)
  }

  getInstrumentPeers(assetChildren: string, sector: string): Observable<any>{
    if(sector){
      return this.http.get(`${API_END_POINTS.RM.instrument_peers}?assetChildren=${assetChildren}&sector=${sector}`)
    } else {
      return this.http.get(`${API_END_POINTS.RM.instrument_peers}?assetChildren=${assetChildren}`)
    }
  }

  updatePeers(algoWorkflowId: string, instrumentId: string, pickrightAssetId: string): Observable<any> {
    return this.http.post(`${API_END_POINTS.RM.update_peers}?algoWorkflowId=${algoWorkflowId}&instrumentId=${instrumentId}`, {pickrightAssetId})
  }
}


export interface State {
  currentPortfolioData: any[]
  reviewPortfolioData: any[]
}

export interface InstrumentsState {
  parentId: string,
  childId: string,
  instruments: any[];
}