import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_END_POINTS } from '@app/config/api.constants';
import { WorkflowData } from '@app/config/worflow.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RMService } from './rm.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private $isLoggedIn: BehaviorSubject<any> = new BehaviorSubject(false)
  isLoggedIn = this.$isLoggedIn.asObservable()
 

  constructor(
    private http: HttpClient,
    private router: Router,
    private rmService: RMService
  ) { }

  getToken(){
    const token = window.localStorage.getItem(environment.token)
    if(token){
      this.$isLoggedIn.next(true)
    } else {
      this.$isLoggedIn.next(false)
    }
    return token
  }
  
  adminLogin(data: any): Observable<any>{
    return this.http.post(API_END_POINTS.AUTH.login, data)
  }

  setToken(token: string){
    this.$isLoggedIn.next(true)
    window.localStorage.setItem(environment.token, token)
  }

  logout(){
    window.localStorage.clear()
    this.$isLoggedIn.next(false)
    this.rmService.saveWorkflowData({
      panNo: '', 
      algoWorkflowId: '', 
      profileId: '', 
      currentStep: '',  
      configCriteria: { criteriaConfigName: '', _id: ''},
      greenUniverseConfig: {greenConfigName: '', _id: ''},
      redUniverseConfig: {redConfigName: '', _id: ''},
      isPublishedListEnable: false,
      parameters: []
    })
    this.rmService.setUserProfile(null)
    this.router.navigate(['/'])
  }
}
